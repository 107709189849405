<template>
  <span v-show="isAllowed" @click="openModal">
    <button class="btn btn-primary">
      {{ buttonName }}
    </button>
  </span>
</template>

<script>
import ButtonMixin from '../mixins/buttonMixin';

export default {
  props: {
    allowedActions: {
      required: true,
      validator: (value) => {
        if (value !== null) {
          return value.constructor.name == 'Array';
        } else {
          return true;
        }
      },
    },
    type: {
      validator: (value) => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    applicationUuid: {
      validator: (value) => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
  },
  mixins: [ButtonMixin],
  data() {
    return {
      actionName: 'ACCEPT',
      actionType: 'RENT_TRANSACTION',
    };
  },
  watch: {
    checkBankAccountInbox(val) {
      if (val && this.isAllowed) {
        this.openModal();
      }
    },
  },
  computed: {
    checkBankAccountInbox: {
      get() {
        return this.$store.state.v2.profile.checkBankAccountInbox;
      },
      set(val) {
        this.$store.commit('v2/profile/SET_CHECK_BANK_ACC_INBOX', val);
      },
    },
  },
  methods: {
    async openModal() {
      let resultCheck = true;
      if (!this.checkBankAccountInbox) {
        resultCheck = await this.$store.dispatch('v2/profile/checkUserCanAcceptBooking');
      }
      if (resultCheck === false) {
        this.$modal.show('modal-input-bank-account');
      } else {
        this.$modal.show('rent-accept-modal', {
          uuid: this.applicationUuid,
        });
      }
    },
  },
};
</script>
